import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../../components";

const GoogleAds = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Google Ads",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/ads-google/",
      }}
    >
      <section className="container-fluid google_ads_section">
        <div className="text-center">
          <h1>Google Ads</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Google Ads campaigns are a significant part of our marketing efforts. It is our specialty. That is why one of the companies within our group - WHEN Agency - has the highest distinction awarded by Google - the Google Premier Partner and actively participates in events organized by Google.
            </p>
            <p className="text-center">
              Google Ads campaigns are the best way to reach your customers. Thanks to this form of promotion, you will notice quick effects in the form of increased traffic on the website, which will also turn into increased sales in the online store or on the booking platform, or in increasing interest in the services you offer.
            </p>
            <p className="text-center">
              Thanks to Google Ads, your products and services will be visible when you search for them on Google and on various other websites, as well as on YouTube.
            </p>
            <div className="row">
              <div className="col-md-4">
                <div className="inner">
                  <h4>Web search</h4>
                  Text ads and product search ads.
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner">
                  <h4>Web</h4>
                  Image, video, and text ads on Google partner sites and YouTube.
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner">
                  <h4>Remarketing</h4>
                  Building brand awareness and reaching customers who have already visited your website.
                </div>
              </div>
            </div>
            With Google Ads campaigns, you can maximize the reach of your advertising campaigns. Depending on your individual needs and the expected results, we can offer you:
            <ul>
              <li>
                Effective text ads and product ads in Google search network, that is used by over 
                <strong>90%</strong> Internet users worldwide
                (source:
                https://gs.statcounter.com/search-engine-market-share))
              </li>
              <li>
                Ads filtered by specific topics
              </li>
              <li>
                YouTube Ads
              </li>
              <li>
                Image ads that appear to users with specific interests or intentions
              </li>
              <li>
                Effective remarketing activities, encouraging the return of users who have already been interested in your products and services
              </li>
              <li>
                Comprehensive configuration of analytical tools
              </li>
              <li>Full campaign optimization</li>
              <li>
                Reports and analyzes that will allow you to fully control the progress of our work
              </li>
            </ul>
            We have 5 certified and experienced Google Ads specialists on board, and one of them will be dedicated to your campaign. We like to share our knowledge, so every now and then all of our campaigns are also being checked and verified by other specialists, thanks to which the campaign for your business is always perfectly optimized.
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Other services</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Web Analytics</span>
            <Link to="/en/offer/web-analytics/">See</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/en/offer/social-media/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/en/offer/linkedin-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/en/offer/facebook-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Banner Ads</span>
            <Link to="/en/offer/banner-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>International Marketing Campaigns</span>
            <Link to="/en/offer/international-campaigns/">see</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default GoogleAds;
